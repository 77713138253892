@import "~@mhe/openlearning-themekit-icons/icons";
@import "~@mhe/ngx-shared/assets/theming/openlearning"; // BS5 theme
@import "~@mhe/ngx-shared/assets/themekit/themes/open-learning"; // legacy theme needed for transition phase(s)
@import '~dragula/dist/dragula.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~@mhe/air-skin/air-item.scss';

.user-profile-popover,
.app-menu-popover,
.import-export-notification-popover {
  margin-right: 60px;

  mhe-popover {
    .popover-content {
      width: 200px;
    }
  }
}
// app-menu.component.scss
.import-export-notification-popover {
  .popover {
    max-width: 360px;
    .popover-content {
      width: 260px;
    }
  }
}
//
// Assess "discard modal" overrides
@import '~@mhe/ngx-shared/assets/themekit/open-learning/theming/variables/colors';

.assess-my-download-modal-class {
  .modal-dialog {
    width: 90%;
    max-width: 1048px;
  }

  &.error {
    .modal-dialog {
      width: 42%;
    }
  }
}

mhe-popover .popover {
  position: relative;
  .arrow {
    margin-left: 42px;
  }

  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  &.left > .arrow,
  &.right > .arrow {
    top: 50%;
    margin-left: unset;
  }

  &.left > .arrow {
    border-left-color: #afacac;
  }

  &.right > .arrow {
    border-right-color: #afacac;
  }

  &.bottom > .arrow {
    border-bottom-color: #afacac;
  }

  &.top > .arrow {
    border-top-color: #afacac;
  }

  .popover-content {
    padding: 0;
    font-size: 14px;
    display: flex;
    flex-flow: column;

    .legend-list,
    .my-downloads-button__popover-container {
      padding: 20px;
    }
    atq-create-assessment form {
      margin: 30px;
    }
  }
}

.print-passage-popover mhe-popover .popover {
  &.right > .arrow {
    border-right-color: #009bb5 !important;
    border-left-width: 2px;

    &:after {
      left: 3px !important;
    }
  }

  .popover-content {
    padding: 20px !important;
    border: 2px solid #009bb5 !important;
  }
}
//
// preview-entities.component.scss
$enhanced-preview-header-height: 45px;

.preview-entities-modal .modal-dialog {
  max-width: 1350px;
  width: 95%;
  height: 95%;

  .modal-content {
    overflow: unset !important;
    height: 100%;

    .modal-body {
      padding: 0 !important;
      width: 100%;
      height: calc(100% - #{$enhanced-preview-header-height});
      flex-direction: column;
      // fixing specific for macOS scroll bug
      overflow: hidden;
    }
  }
}

.preview-entities-modal {
  .modal-dialog {
    margin: 20px auto;

    @media (min-width: 768px) {
      width: 700px;
    }
    @media (min-width: 992px) {
      width: 950px;
    }
    @media (min-width: 1200px) {
      width: 1150px;
    }
    @media (min-width: 1400px) {
      width: 1350px;
    }
  }

  .modal-dialog .modal-content .scrollable-body {
    max-height: calc(95vh - 65px);
    overflow-y: unset;
  }

  .modal-body {
    overflow: unset;

    .btn {
      font-size: 16px;
      position: relative;
      width: auto;
    }
  }
}

.course-resources-modal {
  .modal-dialog {
    @media (min-width: 768px) {
      width: 700px;
    }
    @media (min-width: 992px) {
      width: 950px;
    }
    @media (min-width: 1200px) {
      width: 1150px;
    }
    @media (min-width: 1400px) {
      width: 1350px;
    }
  }
  .modal-dialog .modal-content .scrollable-body {
    max-height: calc(95vh - 65px);
    overflow-y: unset;
  }

  .modal-body {
    overflow: unset;
  }
}
//
// preview-entity.component.scss
.preview-entity-modal .modal-dialog {
  min-width: 800px;
  top: 10vh;
}
//
// send-copy.component.scss
// TODO: Fix width for retry send copy modal
.send-copy-modal {
  .modal-dialog {
    @media (min-width: 992px) {
      width: 900px;
    }
  }
}
//

.folder-entities-modal {
  width: 300px;
}

//enhanced-preview.component.scss
.enhanced-preview-footer {
  mhe-dropdown button {
    min-width: 195px;
    margin: 0 6px;
  }
}

// making cursor grabbing when item in assessment is being dragged projects/dle-assess-authoring-ui-submodules/src/lib/features/assess-authoring/assessment-builder/item-list/item-list.component.ts
.gu-mirror {
  cursor: grabbing !important;
}

aa-item-hint,
aa-item-explanation,
aa-item-example-response {
  mhe-radio-button > label > span {
    font-weight: bold;
  }
}

.label-point {
  font-weight: bold;
}

// fixes dropdown in aa-modal for create existing passage in item-passage.component
.cdk-overlay-container {
  z-index: 1100 !important;
}

.panel-body {
  padding: 2.15rem !important;
}

.cdk-overlay-container .modal-dialog {
  display: flex;
  max-width: 60rem;
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width) !important;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
